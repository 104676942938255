import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';
import { ROUTES } from '../../../configs';
import { getUserData, clearStorages } from '../../../utils/storage';

export default function Profile() {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [dataUser] = useState(getUserData);
  DropdownClose(wrapperRef, () => setOpen(false));

  return (
    <section className={styles.profile} onClick={() => setOpen(!open)} ref={wrapperRef}>
      <img src={dataUser.avatar || '/assets/ic-user-default.svg'} />
      <section>
        <h3>{dataUser.name}</h3>
        <p>{dataUser.username}</p>
      </section>
      {open && <DropdownItems />}
    </section>
  );
}

export function DropdownItems (){
  return (
    <section className={styles.dropdown}>
      <Dropdown onClick={logout}>Log Out</Dropdown>
    </section>
  );
}

const DropdownClose = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener) ;
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const logout = () => {
  clearStorages();
  location.href = ROUTES.LOGIN;
};

export function Dropdown (props){
  return (
    <a href={props.link} onClick={props.onClick}>
      {props.children}
    </a>
  );
}

Dropdown.defaultProps = {
  children: '',
  link: '',
  onClick: () => {}
};

Dropdown.propTypes = {
  children: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
};
