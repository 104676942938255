import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import loan from '../pages/Loan/reducer';
import loanDetail from '../pages/LoanDetail/reducer';
import loanPackage from '../pages/LoanPackage/reducer';
import loanPackageDetail from '../pages/LoanPackageDetail/reducer';
import loanPackageForm from '../pages/LoanPackageForm/reducer';
import loanBorrowerDetail from '../pages/LoanBorrowerDetail/reducer';
import loanLandStatus from '../pages/LoanLandStatus/reducer';
import loanLandBudget from '../pages/LoanLandBudget/reducer';
import login from '../pages/Login/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  loan,
  loanDetail,
  loanPackage,
  loanPackageDetail,
  loanPackageForm,
  loanBorrowerDetail,
  loanLandStatus,
  loanLandBudget,
  login,
  routing: routerReducer
});

export default rootReducer;
