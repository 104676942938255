import { getPackageSchema, loanPackage } from '../../utils/fetch';
import { FAILED, LOADING, SUCCESS } from './constants';

export function fetchPackageSchema(id) {
  return dispatch => {
    const key = 'PackageSchema';
    dispatch(loadingAction(true, key));

    getPackageSchema(id)
      .then(res => {
        if (res.data) {
          dispatch(successAction(res.data, key));
        } else {
          dispatch(failedAction(res.message));
        }
        dispatch(loadingAction(false, key));
      })
      .catch(err => {
        const message = err.statusCode === 400 ? 'Data tidak ditemukan' : err.message;

        dispatch(failedAction(message));
        dispatch(loadingAction(false, key));
      });
  };
}

export function packageStore(method,data) {
  return dispatch => {
    const key = 'Form';
    dispatch(loadingAction(true, key));

    loanPackage(method,data)
      .then(() => {
        dispatch(failedAction('SUKSES'));
      })
      .catch(({ message }) => dispatch(failedAction(message)));

  };
}

export function resetMessage() {
  return failedAction('');
}

function failedAction(message) {
  return { type: FAILED, message };
}

function loadingAction(isLoading, key) {
  return { type: LOADING, isLoading, key };
}

function successAction(data, key) {
  return { type: SUCCESS, data, key };
}
