import axios from 'axios';
import { getToken, clearStorages } from './storage';
import { ROUTES } from '../configs';

const BASIC_URL = (mode => {
  if (mode === 'production') {
    return 'https://partner-api.agreeculture.id';
  }
  return process.env.PARTNER_API_BASE_URL;
})(process.env.MODE);

const BASE_URL = `${BASIC_URL}/api`;
const BASIC_AUTH = { Authorization: 'Basic dGVsa29tOmRhMWMyNWQ4LTM3YzgtNDFiMS1hZmUyLTQyZGQ0ODI1YmZlYQ==' };
const BASIC_TOKEN = { Authorization: `Bearer ${getToken()}` };

const fetch = (url, method, paramBody, paramHeader) => {
  const options = {
    method: method,
    url: url,
    data: paramBody,
    headers: paramHeader,
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then(res => resolve(res.data))
      .catch(err => {
        const defaultError = {
          code: 500,
          status: 'error',
          message: 'Failed to fetch data. Please contact developer.'
        };

        if (!err.response) {
          reject(defaultError);
        } else if (!err.response.data) {
          reject(defaultError);
        } else if (err.response.statusText === 'Unauthorized') {
          clearStorages();
          location.href = ROUTES.LOGIN;
        }
        else {reject(err.response.data);}
      });
  });
};

//LOGIN
export const loginUser = async (data) => {
  return fetch(`${BASE_URL}/v1/users/login`, 'POST', data, BASIC_AUTH);
};

//PENGAJUAN
export const loanList = async (param) => {
  return fetch(`${BASE_URL}/v1/modal/loans-submission${param}`, 'GET', {}, BASIC_TOKEN);
};

export const loanDetails = async (id) => {
  return fetch(`${BASE_URL}/v1/modal/loans/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const loanPool = async (id) => {
  return fetch(`${BASE_URL}/v2/modal/loans/${id}?template=borrowerLender`, 'GET', {}, BASIC_TOKEN);
};

export const lenderDetails = async (id) => {
  return fetch(`${BASE_URL}/v1/modal/lender/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const loanActions= async (id) => {
  return fetch(`${BASE_URL}/v1/modal/loans/${id}/available-action`, 'GET', {}, BASIC_TOKEN);
};

export const getPlantingSeason= async (id) => {
  return fetch(`${BASE_URL}/v1/agree/plantingSeason/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const getCompany= async (id) => {
  return fetch(`${BASE_URL}/v1/agree/company/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const downloadAllLoan= async (size, type) => {
  return fetch(`${BASE_URL}/v1/modal/loans-submission?isExcel=true&exportType=${type}&sort={"createdAt":-1}&size=${size}`, 'GET', {}, BASIC_TOKEN);
};

export const downloadOnePageLoan= async (page, type) => {
  return fetch(`${BASE_URL}/v1/modal/loans-submission?page=${page}&size=10&isExcel=true&exportType=${type}&sort={"createdAt":-1}`, 'GET', {}, BASIC_TOKEN);
};

//PAKET PINJAMAN
export const getListPackage = async (param = '') => {
  return fetch(`${BASE_URL}/v1/modal/loan-packages/summary${param}`, 'GET', {}, BASIC_TOKEN);
};

export const getPackageDetail  = async (id) => {
  return fetch(`${BASE_URL}/v1/modal/loan-packages/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const getPackageSchema  = async (id) => {
  return fetch(`${BASE_URL}/v1/modal/loan-packages-schema/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const loanPackage  = async (method, data) => {
  return fetch(`${BASE_URL}/v1/modal/loan-packages`, method, data, BASIC_TOKEN);
};

//ACTIONS BUTTON
export const setActionsButton= async (param_url, method, data) => {
  return fetch(`${BASIC_URL}${param_url}`, method, data, BASIC_TOKEN);
};

//DETAIL BORROWER
export const getBorrowerDetail= async (id) => {
  return fetch(`${BASE_URL}/v2/modal/loans/${id}?template=lenderportal`, 'GET', {}, BASIC_TOKEN);
};

//DETAIL LAND STATUS
export const getLandStatus= async (id) => {
  return fetch(`${BASE_URL}/v1/agree/plantingSeason/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const getLandActivity= async (status,id) => {
  return fetch(`${BASE_URL}/v1/agree/${status}/plantingSeason/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const getTransactionActivity= async (querry,id) => {
  return fetch(`${BASE_URL}/v1/agree/transaction?query={"${querry}":"${id}"}`, 'GET', {}, BASIC_TOKEN);
};

//DETAIL LAND BUDGET / RAB

export const getLandBudget= async (id) => {
  return fetch(`${BASE_URL}/v1/agree/plantingSeason/budget-plan/${id}`, 'GET', {}, BASIC_TOKEN);
};

export const getCommodity= async (id) => {
  return fetch(`${BASE_URL}/v1/agree/commodity/${id}`, 'GET', {}, BASIC_TOKEN);
};



