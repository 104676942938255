import { FAILED, LOADING, LASTPAGELOAN, META, SUCCESS } from './constants';

const initialState = {
  data: [],
  dataSummary: {},
  dataSubmit: {},
  isLoading: false,
  isLoadingSummary: false,
  lastPageLoan: '',
  meta:{},
  message: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, isLoading, lastPageLoan, message, meta, data, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
      };
    case LASTPAGELOAN:
      return {
        ...state,
        lastPageLoan,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`] : isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`] : data,
      };
    case META:
      return {
        ...state,
        meta
      };
    default:
      return state;
  }
}
