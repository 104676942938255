import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { Router } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ROUTES } from './configs';
import AppContextProvider from './contexts';
import PageBase from './components/Layouts/PageBase';
import pages from './pages';
import { checkExpireTime, clearStorages, getToken } from './utils/storage';

if (location.pathname!==ROUTES.LOGIN && location.pathname!==ROUTES.REGISTER) {
  if (!getToken()) {
    location.href = ROUTES.LOGIN;
  } else if (checkExpireTime()) {
    clearStorages();
    location.href = ROUTES.LOGIN;
  }
} else if (location.pathname===ROUTES.LOGIN && getToken()){
  location.href = ROUTES.DASHBOARD;
}

export const MainPages = () => (
  <PageBase>
    <Switch>
      <Route component={pages.Loan} exact path={[ROUTES.DASHBOARD,ROUTES.HOME,ROUTES.LOAN]} />
      <Route component={pages.LoanDetail} exact path={`${ROUTES.LOAN}/:id`} />
      <Route component={pages.LoanBorrowerDetail} exact path={`${ROUTES.LOAN}/details/:id`} />
      <Route component={pages.LoanLandStatus} exact path={`${ROUTES.LOAN}/land/:id/:seasonId`} />
      <Route component={pages.LoanLandBudget} exact path={`${ROUTES.LOAN}/land/RAB/:id/:seasonId/:commodityId`} />
      <Route component={pages.LoanPackage} exact path={ROUTES.LOANPACKAGE} />
      <Route component={pages.LoanPackageForm} exact path={`${ROUTES.LOANPACKAGE}/form/:id`} />
      <Route component={pages.LoanPackageDetail} exact path={`${ROUTES.LOANPACKAGE}/:id`} />
      <Route component={pages.Error404} />
    </Switch>
  </PageBase>
);

const App = ({ history, store }) => (
  <Provider store={store}>
    <Router history={history}>
      <AppContextProvider>
        <Switch>
          <Route component={pages.Login} exact path={ROUTES.LOGIN} />
          <Route component={MainPages} />
        </Switch>
      </AppContextProvider>
    </Router>
  </Provider>
);

export default hot(App);

App.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};
