import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from '../../elements/Header';
import { ROUTES } from '../../../configs';
import { clearStorages } from '../../../utils/storage';
import { ParentName } from '../../../utils/format';
import styles from './styles.scoped.css';

const navs = ['LOAN', 'LOANPACKAGE', 'CONFIG'];

export default function PageBase({ children }) {
  useEffect(() => {
    const app = document.getElementById('app');
    app.className = 'pagebase';
  }, []);

  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  // if (path === 'dashboard' || path === 'home' || !path) {
  //   path = 'loan';
  // }

  const logOut = () => {
    clearStorages();
    location.href = ROUTES.LOGIN;
  };

  return (
    <>
      <Header />
      <aside className={styles.aside}>
        <nav>
          {navs.map((n, idx) => (
            <Link
              className={`/${path}`=== ROUTES[n] ? styles.active : ''}
              key={idx}
              to={ROUTES[n]}>
              <img src={`/assets/ic-${n.toLowerCase()}.svg`} />
              {ParentName(`menu_${n.toLowerCase()}`)}
            </Link>
          ))}
        </nav>
        <footer>
          <button onClick={logOut}>
            <img src="/assets/ic-logout.svg" />
            Log Out
          </button>
        </footer>
      </aside>
      <main className={styles.main}>
        {children}
      </main>
    </>
  );
}

PageBase.defaultProps = {
  children: null,
};

PageBase.propTypes = {
  children: PropTypes.node,
};
