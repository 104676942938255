import React from 'react';

const Suspensed = (Element) => function suspense(props) {
  return (
    <React.Suspense fallback={<div />}>
      <Element {...props} />
    </React.Suspense>
  );
};

export default {
  Loan: Suspensed(React.lazy(() => import('./Loan'))),
  LoanDetail: Suspensed(React.lazy(() => import('./LoanDetail'))),
  LoanBorrowerDetail: Suspensed(React.lazy(() => import('./LoanBorrowerDetail'))),
  LoanLandStatus: Suspensed(React.lazy(() => import('./LoanLandStatus'))),
  LoanLandBudget: Suspensed(React.lazy(() => import('./LoanLandBudget'))),
  LoanPackage: Suspensed(React.lazy(() => import('./LoanPackage'))),
  LoanPackageDetail: Suspensed(React.lazy(() => import('./LoanPackageDetail'))),
  LoanPackageForm: Suspensed(React.lazy(() => import('./LoanPackageForm'))),
  Login: Suspensed(React.lazy(() => import('./Login'))),
  Error404: Suspensed(React.lazy(() => import('./Error404'))),
};
