import { FAILED, LOADING, SUCCESS } from './constants';
import { fetchPackageSchema } from './actions';

const initialState = {
  dataInitialForm: {},
  dataPackageSchema: [],
  fetchPackageSchema,
  isLoadingPackageSchema: false,
  isLoadingForm: false,
  message: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, isLoading, message, key, data } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
        isLoadingForm: false,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
      };
    default:
      return state;
  }
}
