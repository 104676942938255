export const thousand = val => (
  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
);

export const rupiah = val => (`Rp. ${thousand(val)}`);

export const fileAccept = arr => {
  let val = [];
  arr.map((item, i) => {
    const file = item.split('/');
    val += file[1].toUpperCase();
    if (i+1 < arr.length){
      val += ', ';
    }
  });
  return val;
};

export const convertOptions = arr => {
  let opt = [];
  arr.map((item) => {
    if (!item.key) {
      opt.push({ text:item.label, value:item.value, key:item.value });
    } else {
      opt.push(item);
    }
  });

  return opt;
};

export const capitalized = value => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const ParentName = parent => {
  switch (parent) {
    case 'menu_loan' : return 'Pengajuan';
    case 'menu_loanpackage': return 'Paket Pinjaman';
    case 'menu_config' : return 'Pengaturan';
    case 'status_approval' : return 'Nominal Pengajuan Disetujui';
    case 'status_cancelled' : return 'Dibatalkan';
    case 'status_disbursed' : return 'Telah Dicairkan';
    case 'status_paid' : return 'Lunas';
    case 'status_paid_validation' : return 'Bukti Pelunasan';
    case 'status_processed' : return 'Proses Approval';
    case 'status_rejected' : return 'Ditolak';
    case 'status_surveyed' : return 'Proses Survey';
    case 'status_submission' : return 'Pending';
    case 'status_pending' : return 'Pending';
    case 'status_request_disbursement' : return 'Pengajuan Pencairan';
    case 'status_paid_validation_valid' : return 'Bukti Pelunasan';
    default : return '';
  }
};

export const TitleName = title => {
  switch(title) {
    case 'profileData' : return 'Info Pribadi';
    case 'familyData' : return 'Info Keluarga';
    case 'spouseData' : return 'Info Pasangan';
    case 'addressData' : return 'Info Alamat';
    case 'businessData' : return 'Info Usaha Calon Peminjam';
    case 'addressBusinessData' : return 'Info Alamat Usaha';
    case 'employmentData' : return 'Info Pekerjaan';
    case 'bankData' : return 'Info Bank';
    case 'emergencyData' : return 'Info Darurat';
    case 'photosData' : return 'Foto Dokumen';
    case 'collateralData' : return 'Jaminan';
    default : return '';
  }
};

export const subTitle = [
  { name:'name', value:'Nama' },
  { name:'nik' , value: 'NIK' },
  { name:'phoneNumber' , value: 'Nomer Handphone' },
  { name:'dateOfBirth' , value: 'Tanggal Lahir' },
  { name:'email' , value: 'Email' },
  { name:'placeOfBirth' , value: 'Tempat Lahir' },
  { name:'education' , value: 'Pendidikan' },
  { name:'religion' , value: 'Agama' },
  { name:'gender' , value: 'Jenis Kelamin' },
  { name:'citizenship' , value: 'Kewarganegaraan' },
  { name:'countryOfResidence' , value: 'Negara' },
  { name:'homeAddress' , value: 'Alamat Rumah' },
  { name:'district' , value: 'Kabupaten/Kota' },
  { name:'subDistrict' , value: 'Kecamatan' },
  { name:'province' , value: 'Provinsi' },
  { name:'rtRw' , value: 'RT/RW' },
  { name:'village' , value: 'Kelurahan/Desa' },
  { name:'lengthOfStay' , value: 'Menghuni Sejak' },
  { name:'ownershipStatus' , value: 'Status Kepemilikan' },
  { name:'postalCode' , value: 'Kode Pos' },
  { name:'businessField', value: 'Bidang Usaha' },
  { name:'economicNewSector' , value: 'Sektor Ekonomi Baru' },
  { name:'economicNewSubSector' , value: 'Sub Sektor Ekonomi Baru' },
  { name:'economicSector' , value: 'Sektor Ekonomi' },
  { name:'economicSubSector' , value: 'Sub Sektor Ekonomi' },
  { name:'employmentType' , value: 'Jenis Pekerjaan' },
  { name:'farmerCardNumber' , value: 'Nomer kartu Petani' },
  { name:'income' , value: 'Pendapatan' },
  { name:'initialInvestment' , value: 'Modal Usaha' },
  { name:'legalLetterNumber' , value: 'Nomer Surat Resmi' },
  { name:'lengthOfWork' , value: 'Bekerja Sejak' },
  { name:'npwp' , value: 'NPWP' },
  { name:'officeAddress' , value: 'Alamat Kantor' },
  { name:'offtaker Name' , value: 'Nama Offtaker' },
  { name:'poktanName' , value: 'Nama Poktan' },
  { name:'subBusinessField' , value: 'Sub Bidang Usaha' },
  { name:'totalCost' , value: 'Total Pengeluaran' },
  { name:'totalEmployee' , value: 'Jumlah Pekerja' },
  { name:'businessPermitNumber' , value: 'Nomer Izin Usaha' },
  { name:'businessPermitType' , value: 'Jenis Izin Usaha' },
  { name:'businessSector' , value: 'Sektor Usaha' },
  { name:'businessStartDate', value: 'Tanggal Mulai Usaha' },
  { name:'initialTurnOver' , value: 'Kembali Modal' },
  { name:'totalBranches' , value: 'Total Cabang' },
  { name:'totalEmployees' , value: 'Total Karyawan' },
  { name:'umkmType' , value: 'Jenis UMKM' },
  { name:'businessHomeAddress' , value: 'Alamat Usaha' },
  { name:'familyCardPhoto' , value: 'Foto KK' },
  { name:'farmerCardPhoto' , value: 'Foto Kartu Petani' },
  { name:'farmerPhoto' , value: 'Foto Diri' },
  { name:'farmerSpousePhoto' , value: 'Foto Pasangan' },
  { name:'housePhoto' , value: 'Foto Rumah' },
  { name:'ktpPhoto' , value: 'Foto KTP' },
  { name:'otherDocumentPhoto' , value: 'Foto Dokumen Lain' },
  { name:'spouseKtpPhoto' , value: 'Foto KTP Pasangan' },
  { name:'spouseEmployment' , value: 'Pekerjaan Pasangan' },
  { name:'dependents', value: 'Jumlah Tanggungan' },
  { name:'motherName', value: 'Nama Ibu' },
  { name:'maritalStatus' , value: 'Status' },
  { name:'spouseDateOfBirth' , value: 'Tanggal Lahir Pasangan' },
  { name:'spouseGender' , value: 'Jenis Kelamin' },
  { name:'spouseMonthlyIncome' , value: 'Pendapatan Bulanan Pasangan' },
  { name:'spouseName' , value: 'Nama Pasangan' },
  { name:'spouseNik' , value: 'NIK Pasangan' },
  { name:'spousePlaceOfBirth' , value: 'Tempat Lahir Pasangan' },
  { name:'emergencyContactName' , value: 'Nama Kontak Darurat' },
  { name:'phoneNumberEmergencyContact' , value: 'Nomer Handphone Kontak Darurat' },
  { name:'relationship' , value: 'Hubungan' },
  { name:'accountName' , value: 'Nama Pemilik Rekening' },
  { name:'accountNumber', value: 'Nomer Rekening' },
  { name:'bankBranch' , value: 'Cabang' },
  { name:'bankName', value: 'Nama Bank' },
];

export const phasePlanting  = phase => {
  switch(phase) {
    case 'preplanted' : return 'Pratanam';
    case 'prePlanted' : return 'Pratanam';
    case 'planted' : return 'Tanam';
    case 'fertilized' : return 'Perawatan';
    case 'harvested' : return 'Panen';
    case 'distilled' : return 'Pascapanen';
    case 'transaction' : return 'Transaksi';
    default : return '';
  }
};

export const phaseRecord  = phase => {
  switch(phase) {
    case 'prePlanted' : return 'prePlanted';
    case 'planted' : return 'planted';
    case 'fertilized' : return 'fertilizer';
    case 'harvested' : return 'harvest';
    case 'distilled' : return 'distil';
    case 'transaction' : return 'transaction';
    default : return '';
  }
};

export const titleBudidaya  = title => {
  switch(title) {
    case 'farmerName' : return 'Petani';
    case 'landId' : return 'ID Lahan';
    case 'populationSuggestion' : return 'Jumlah Populasi';
    case 'name' : return 'Status Lahan';
    case 'spacingX' : return 'Jarak Tanam (cm)';
    case 'images' : return 'Foto';
    case 'mitraName' : return 'Mitra';
    case 'commodityName' : return 'Komoditas';
    case 'varietyName' : return 'Varietas';
    case 'status' : return 'Jenis Kegiatan';
    case 'scheme' : return 'Skema Tanam';
    case 'seed' : return 'Kebutuhan Bibit';
    case 'type' : return 'Tipe';
    case 'production' : return 'Produksi';
    case 'landSize' : return 'Luas Lahan (ha)';
    case 'finalWeight' : return 'Bobot Akhir';
    case 'initialWeight' : return 'Bobot Awal';
    case 'description' : return 'Catatan';
    case 'typeName' : return 'Jenis';
    case 'descriptionByFA' : return 'Catatan FA';
    case 'expenditure' : return 'Biaya';
    default : return '';
  }
};

