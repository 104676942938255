import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';
import Profile from '../ProfileBanner';

export default function Header() {

  return (
    <header className={styles.header}>
      <section>
        <img src="/assets/logo.svg"/>
        <NavBar>
          <NavItems data={<Profile />} />
        </NavBar>
      </section>
    </header>
  );
}

Header.defaultProps = {
  children: {},
  data: {}
};

Header.propTypes = {
  children: PropTypes.object,
  data: PropTypes.object
};

export function NavBar(props) {
  return (
    <nav className={styles.navbar}>
      <ul>{props.children}</ul>
    </nav>
  );
}

NavBar.defaultProps = {
  children: {}
};

NavBar.propTypes = {
  children: PropTypes.object
};

export function NavItems(props) {
  return (
    <li>{props.data}</li>
  );
}

NavItems.defaultProps = {
  data: {}
};

NavItems.propTypes = {
  data: PropTypes.object
};
