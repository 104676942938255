const base = 'Agree Modal Lender | ';
export const ROUTES = {
  HOME: '/',
  CONFIG: '/config',
  DASHBOARD: '/dashboard',
  LOAN: '/loan',
  LOANPACKAGE: '/loanpackage',
  LOANPACKAGEFORM: '/loanpackageform',
  LOGIN: '/login',
};

export const TITLE = {
  HOME: `${base} Home`,
  CONFIG: `${base} Pengaturan`,
  DASHBOARD: `${base} Dashboard`,
  LOAN: `${base} Pengajuan`,
  LOANPACKAGE: `${base} Paket Pinjaman`,
  LOANPACKAGEFORM: `${base} Form Paket Pinjaman`,
  LOGIN: `${base} Login`,
};
