import { FAILED, LASTPAGE, LOADING, META, SUCCESS } from './constants';

const initialState = {
  data: [],
  isLoading: false,
  meta:{},
  lastPage: '',
  message: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, isLoading, message, meta, lastPage, data } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
      };
    case LASTPAGE:
      return {
        ...state,
        lastPage,
      };
    case LOADING:
      return {
        ...state,
        isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        data,
      };
    case META:
      return {
        ...state,
        meta,
      };
    default:
      return state;
  }
}
