import { FAILED, LOADING, LASTPAGE, SUCCESS, PLANTING } from './constants';

const initialState = {
  data: {},
  dataLender: {},
  dataLoanPackage: {},
  dataLoanRequestedData: {},
  dataLoanSubmissionData: {},
  dataCollateral: {},
  dataLoanActions: { actions:[]},
  dataPlantingSeason: [],
  dataPlanting: {},
  dataLenderPool: [],
  dataCompanyName: '',
  isLoading: false,
  isLoadingLoanActions: false,
  isLoadingForm: false,
  message: '',
  lastPage: ''
};

export default function reducer(state = initialState, action = {}) {
  const { type, isLoading, message, data, key, lastPage } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
      };
    case LASTPAGE:
      return {
        ...state,
        lastPage
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
      };
    case PLANTING:
      return {
        ...state,
        [`data${key}`]: data,
      };
    default:
      return state;
  }
}
