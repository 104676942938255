import { FAILED, LOADING, SUCCESS, ACTIVITY } from './constans';

const initialState = {
  dataStatus: {},
  dataPrePlanted: [],
  dataPlanted: [],
  dataFertilized: [],
  dataHarvested: [],
  dataDistilled: [],
  dataTransaction: [],
  isLoading: false,
  isLoadingBarActions: false,
  isLoadingLandStatus: false,
  isLoadingActivity: false,
  message: '',
};

export default function reducer(state = initialState, action = {}) {
  const { type, isLoading, message, data, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data
      };
    case ACTIVITY:
      return {
        ...state,
        [`data${key}`]: data
      };
    default:
      return state;
  }
}

